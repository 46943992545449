import { Product, ProductAttributeValue, ProductValue } from "gatsby-source-harvia-pim/types/plugin"

/**
 * Get formatted unit as string.
 * @param unit
 */
export function formatUnit(unit: string) {
  if (typeof unitMap[unit] != "undefined") {
    return unitMap[unit]
  }
  console.warn("Unhandled unit", unit)
  return `${unit}`
}

/**
 * Ei näytetä tietoja näillä arvoilla
 */
export const filteredValues = ["na", "N/A", "NA"]

/**
 * PIM units mapped to string representation.
 */
const unitMap: Record<string, string> = {
  METER: "m",
  MILLIMETER: "mm",
  CUBIC_METER: "m³",
  KILOGRAM: "kg",
  CELSIUS: "°C",
  EUR: "€",
  HOUR: "h",
  WATT: "W",
  KELVIN: "K",
  SECOND: "s",
  SQUARE_METER: "m²",
  SQUARE_MILLIMETER: "mm²",
  LITER: "l",
  undefined: "",
  null: "",
}

/**
 * Get single value from product data.
 * @param data
 * @param key
 */
export function getValue(values?: ProductValue[], key?: string): ProductValue | undefined {
  const res = values?.find(value => value.name === key)
  // console.debug(`Product value "${key}"`, res)
  return res
}

/**
 * Get multiple values from product data as array.
 */
export const getValues = (values: ProductValue[], keys: string[]): ProductValue[] => {
  const res: ProductValue[] = []
  for (let i = 0; i < keys.length; i++) {
    const value = getValue(values, keys[i])
    if (value) {
      res.push(value)
    }
  }
  return res
}

/**
 * Get product values as string.
 * @param list
 */
export function stringValue(list?: ProductValue | string): string {
  if (!list) {
    return ""
  }
  if (typeof list === "string") {
    return list
  }

  return list.value
    .map((attr: ProductAttributeValue) => {
      if (filteredValues.includes(attr.data)) {
        return
      }

      switch (attr.type) {
        case "ProductMetric": {
          const val = attr.metric
          //if (isNaN(val.amount)) return "na"
          return `${!isNaN(val.amount) ? val.amount : "-"}&nbsp;${formatUnit(val.unit)}`
        }
        case "ProductOption":
          return attr.text
        case "ProductPrice":
          return attr.prices.map(itm => `${itm.amount}&nbsp;${formatUnit(itm.currency)}`).join(", ")
        case "string":
          return attr.text //  TODO: Joidenkin kenttien arvo on html tai json
        case "number":
          return attr.numeric
        case "boolean":
          return attr.boolean
        default:
          console.warn("Unhandled value type:", attr.type)
          return `${attr.text}`
      }
    })
    .join(", ")
}

export type ProductCompareValue = { name: string; label: string; value: (ProductValue | null)[] }

/**
 * Get multiple values from multiple products as comparison array. Empty rows are omitted.
 * @param products
 * @param keys
 */
export function getCompareValues(products: Product[], keys: string[]) {
  const res: ProductCompareValue[] = []

  keys.forEach(key => {
    const row: ProductCompareValue = { name: key, label: "", value: [] }

    let hasVal = false

    products.forEach((product, i) => {
      const val = getValue(product.values, key)
      let validVal = false
      if (val && val.value[0]) {
        const attr = val.value[0]
        if (!row.label) {
          row.label = attr.label
        }
        switch (attr.type) {
          case "ProductMetric":
            if (typeof attr.metric.amount !== "undefined" && attr.metric.amount !== null) validVal = true
            break
          case "string":
            if (!filteredValues.includes(attr.text)) validVal = true
            break
          case "ProductOption":
            if (!filteredValues.includes(attr.data) || val.value.length > 1) validVal = true
            break
          case "ProductPrice":
            if (typeof attr.prices[0]?.amount !== "undefined" && attr.prices[0]?.amount !== null) validVal = true
            break
          default:
            if (typeof attr.data !== "undefined" && attr.data !== null) validVal = true
        }
      }
      if (validVal && val) {
        hasVal = true
        row.value[i] = val
      } else {
        row.value[i] = null
      }
    })

    // jos koko rivillä ei labeleita, näytetään key
    if (!row.label) {
      row.label = key
    }

    // jos koko rivillä ei arvoja, piilotetaan rivi
    if (hasVal) {
      res.push(row)
    }
  })

  // console.log(res)

  return res
}
