import { RockonQueryResultType } from "@nitro/gatsby-theme-harvia/src/components/organisms/product/types"
import { useEffect, useState } from "react"
import { Product } from "../types/plugin"
import { PagedResponse, RockonProduct } from "../types/rockon"
import { processProduct } from "../utils/process"

export type QueryResultParams = Omit<RockonQueryResultType, "content">

type Query = {
  parameters: QueryParams
  subQueries?: Query[]
  condition?: string | "AND" | "OR"
}
type QueryParams = Record<string, QueryPart[]>

type QueryPart = {
  operator: string
  firstOperand: string
  secondOperand?: string
}

function getQueryParam(parts: string[]): [string, QueryPart] {
  //const parts = queryString.split(":")
  const attr = parts[0]
  let operator = "EQ" // oletus
  let firstOperand = ""
  let secondOperand = ""

  // Normi haku
  if (parts.length == 2) {
    //
    firstOperand = parts[1]
  } else if (parts.length == 3) {
    // haku operaattorilla, esim "heater_power:lte:10_5"
    operator = parts[1]
    firstOperand = parts[2]
  } else if (parts.length == 4) {
    // haku operaattorilla, kahdella parametrilla esim "heater_power:between_incl:10_5:20"
    operator = parts[1]
    firstOperand = parts[2]
    secondOperand = parts[3]
  }

  return [attr, { operator, firstOperand, secondOperand }]
}

function getQuery(params: string[]) {
  const query = { condition: "AND", parameters: {}, subQueries: [] } as Query
  params?.forEach(itm => {
    const parts = itm.split(":")

    const [attr, queryPart] = getQueryParam(parts)

    // luodaan parametri nimellä
    if (!query.parameters[attr]) query.parameters[attr] = []

    // lisätään hakuparametrit
    query.parameters[attr].push(queryPart)
  })

  return query
}
/**
 * Tuotehaku RockOn:in rajapinnasta. Query-parametri on string, jossa pilkulla eroteltuna hakutermit.
 *
 * Hakutermin muoto on <kenttä>:<arvo> tai <kenttä>:<and|or>:<arvo> jos halutaan lisätä joku kenttä sub-queryksi. Sub-query on aina basequeryä rajaava.
 * Kaikki samaan kenttään kohdistuvat parametrit yhdistetään huomioiden mahdollinen and|or parametri.
 *
 * Huom. tutustu tähän koodiin, niin selviää paremmin mitä parametreilla tehdään.
 *  */
const useOnlineProductQuery = (
  /** RockOn rajapinnan query parametri. "and" ja "or" operaattorit lisäyksenä. Muilta osin voisi käyttää suoraan GET rajapintahakuna */
  query: string | null,
  /** Url params for rockon query */
  parameters: {
    language: string
    /** Number as string */
    size?: string
    /** Number as string */
    page?: string
    sort?: string
    /** Boolean as string */
    fullAssociations?: string
    /** Products with status enabled:false */
    includeDisabledProducts?: boolean
  },
  options?: {
    /** Base query params from website config. */
    baseQuery?: string
  },
  projection?: string[]
) => {
  const [data, setData] = useState<[Product[] | undefined, QueryResultParams | undefined]>([undefined, undefined])

  /** Kaikkiin hakuihin alkurajaus. */
  const baseQueryParams = options?.baseQuery?.split(",") || []

  const paramsString = new URLSearchParams({
    ...parameters,
    includeDisabledProducts: parameters.includeDisabledProducts ? "true" : "false",
  }).toString()

  useEffect(() => {
    if (query === null) {
      return
    }
    const controller = new AbortController()

    function makeRequest() {
      // console.debug("Fetching", queryParam)

      /* TODO. Nämä sivustokohtaisesta konffista!! Ehkä options objektissa mukana? */
      const baseUrl = "https://pim.harvia.com/rockon-ext/api/v1/"
      const apiChannel = "web_pages_harvia_global"
      const apiUrl = `${baseUrl}products/projectionsearch/${apiChannel}`

      /** Alustava rajaus, verkkosivukohtaiset filtterit. Loput rajaukset tehdään subQueryillä */
      const baseQuery = getQuery(baseQueryParams)

      const queryParams = query.split(",")

      /**
       * Kerätään kaikki parametrit joissa on "or" operaattori, ja luodaan niistä oma subQuery jolla condition:"OR"
       * Näin haku kohdistuu eri attribuutteihin, mutta palauttaa jos jokin niistä täsmää. Normihaku vaatii kaikki täsmäämään.
       */
      const orParams: string[] = queryParams
        .map(str => {
          const parts = str.split(":")
          if (parts[1].toLowerCase() === "or") {
            parts.splice(1, 1)
            return parts.join(":")
          }
          return null
        })
        .filter<string>((val): val is string => typeof val === "string")

      /**
       * Kerätään kaikki parametrit joissa on "and" operaattori, ja luodaan niistä jokaisesta oma subQuery
       * Näin saadaan samalla attribuutilla tehtyä hakuja, joissa kaikki arvot pitää täsmätä
       */
      const andParams: string[] = queryParams
        .map(str => {
          const parts = str.split(":")
          // "sub" on taaksepäin yhteensopivuuden takia
          if (parts[1].toLowerCase() === "and" || parts[1].toLowerCase() === "sub") {
            parts.splice(1, 1)
            return parts.join(":")
          }
          return null
        })
        .filter<string>((val): val is string => typeof val === "string")

      /**
       * Loput parametrit, joilla ei ole "and|or" operaattoria.
       * Oletuksena samaa attribuuttia palautetaan "or" operaattorilla, ja eri attribuutit "and" operaattorilla
       * ks. Rockon rest api dokumentaatio
       */
      const restParams = queryParams.filter(str => {
        const parts = str.split(":")
        return parts[1].toLowerCase() !== "or" && parts[1].toLowerCase() !== "and" && parts[1].toLowerCase() !== "sub"
      })

      // Perus hakuparametrit yhteen subQueryyn
      const userQuery = getQuery(restParams)
      baseQuery.subQueries?.push(userQuery)

      // "AND" operaattorilla haut omiin subQueryihin
      andParams.forEach(param => baseQuery.subQueries?.push(getQuery([param])))

      // "OR" operaattorilla haut yhteen subQueryyn
      const orQuery = getQuery(orParams)
      orQuery.condition = "OR"
      orParams.length && baseQuery.subQueries?.push(orQuery)

      /** Lopullinen hakuparametri */
      const finalQuery = {
        projection: projection,
        query: baseQuery,
      }

      window
        .fetch(`${apiUrl}?${paramsString}`, {
          signal: controller.signal,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalQuery),
        })
        .then(res => res.json())
        .then((res: PagedResponse<RockonProduct>) => {
          if (res.content) {
            const { content, ...rest } = res
            // console.debug("Fetched", queryParam, res)
            const newData = res.content.map(rockonProduct => processProduct(rockonProduct, parameters.language))
            // console.debug("Processed fetched data to", newData)

            // Process fullAssociations
            if (res.fullAssociations) {
              newData.forEach(product => {
                for (const key in product.associations) {
                  const tempAssoc = product.associations[key].map(assoc => {
                    const prod = res.fullAssociations?.find(p => p.identifier === assoc.sku)
                    return {
                      ...assoc,
                      product: prod && processProduct(prod, parameters.language),
                    }
                  })
                  product.associations[key] = tempAssoc
                }
              })
            }

            setData([newData, rest])
          }
        })
        .catch(err => {
          console.error("Request failed", err)
        })
    }

    if (query) {
      makeRequest()
    }

    return function cancel() {
      controller.abort()
    }
  }, [query, baseQueryParams.join(), paramsString, projection?.join()])

  return data
}

export default useOnlineProductQuery
