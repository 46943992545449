import { ExternalMedia, LocaleLabel } from "gatsby-source-harvia-pim/types/plugin"
import { RockonExternalMediaType } from "gatsby-source-harvia-pim/types/rockon"

export function transformExternalMedia(externalMedia: RockonExternalMediaType): ExternalMedia {
  const { baseType, children, previewLink, properties, ...rest } = externalMedia

  // "name" should always be in "en-US" locale (TODO make sure assumption is correct)
  const labels: LocaleLabel[] = [{ locale: "en-US", value: rest.name }]
  // add translations
  if (properties.TitleFinnish) {
    labels.push({ locale: "fi-FI", value: properties.TitleFinnish })
  }
  return {
    ...rest,
    labels,
    type: properties.AssetType,
    thumbnail: properties.Thumbnail,
    language: properties.Language,
    masterImage: properties.MasterImage === "true",
  }
}
