import React from "react"
//import placeholder from "../../images/Harvia_no_image.png"
//import * as styles from "./PimImage.module.scss"

type PimImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

const PimImage = (props: PimImageProps) => {
  //console.log('Rendering PimImage', props)
  if (!props.src && !props.srcSet)
    return (
      <>
        {/*
        
        HUOM. vaihdettu käyttämään static-kansion fallaback kuvatiedostoa, koska product category sivuista tulee buildissa muuten > Mt kokoisia.
        
        <img
          alt="Placeholder image"
          {...props}
          src={placeholder}
          style={{ ...props.style, objectFit: "cover" }}
          loading="lazy"
        /> */}
        <img
          {...props}
          alt={"Placeholder"}
          src="/Harvia_no_image.png"
          style={{ ...props.style, objectFit: "cover" }}
          loading="lazy"
        />
      </>
    )

  return <img {...props} />
}

export default PimImage
