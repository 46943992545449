import { ExternalMedia } from "gatsby-source-harvia-pim/types/plugin"
import { ExternalMediaAssetType } from "gatsby-source-harvia-pim/types/rockon"

/**
 * Parse External media array from product value: "external_media_textarea"
 * @param product Product data
 * @param type Get only elements of this asset type
 */
export const getExternalMedia = (productMedia: ExternalMedia[], type: ExternalMediaAssetType): ExternalMedia[] => {
  if (!productMedia?.length) {
    return []
  }
  return productMedia.filter(itm => itm.type === type)
}
