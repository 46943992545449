import { graphql } from "gatsby"
import React from "react"
import CtaToProductBlock from "../../molecules/tileable-blocks/CtaToProductBlock"
//import * as styles from "./DatoCtaToProduct.module.scss"

const DatoCtaToProduct = (props: Queries.DatoCtaToProductFragment) => {
  //console.log("Rendering DatoCtaToProduct", props)

  return <CtaToProductBlock {...props} />
}

export default DatoCtaToProduct

export const query = graphql`
  fragment DatoCtaToProduct on DatoCmsCtaToProduct {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    sku
    text
    title
    linkText
    image {
      ...DatoImageDefault
    }
    textBackground
    layout
    useProductMasterImage
  }
`
