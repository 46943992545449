import { getValue, stringValue } from "@nitro/gatsby-source-harvia-pim/helpers/attribute"
import { getExternalMedia } from "@nitro/gatsby-source-harvia-pim/helpers/media"
import useOnlineProductQuery from "@nitro/gatsby-source-harvia-pim/hooks/useOnlineProductQuery"
import { Link } from "gatsby"
import React from "react"
import { PageContext } from "../../../context/PageContext"
import useProductPath from "../../../hooks/useProductPath"
import Button from "../../atoms/Button"
import PimImage from "../../atoms/PimImage"
import DatoImage from "../../dato/DatoImage"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import MediaTextBlock, { MediaTextBlockLayout } from "./MediaTextBlock"
//import * as styles from "./CtaToProductBlock.module.scss"

export type CtaToProductBlockProps = {
  sku: string | null
  text?: string
  title?: string
  linkText: string
  image?: DatoImage
  textBackground?: HarviaBackgroundColor
  layout: MediaTextBlockLayout
  useProductMasterImage?: boolean
}

const CtaToProductBlock: React.FC<CtaToProductBlockProps> = props => {
  //console.log('Rendering CtaToProductBlock', props)
  const getProductPath = useProductPath()
  const url = getProductPath(props.sku) // linkki buildattuun tuotesivuun. TODO miten toimitaan jos tuotesivua ei buildattu?
  const { localePim } = React.useContext(PageContext)
  const [onlineProducts] = useOnlineProductQuery(
    props.sku ? `sku:${props.sku}` : null,
    {
      language: localePim,
      includeDisabledProducts: true,
    },
    {},
    ["external_media_textarea", "product_marketing_name_short", "marketing_text_short"] // nämä valuet palautetaan
  )
  const product = onlineProducts?.[0]

  if (product === undefined || !props.sku) {
    // still fetching data
    return null
  }

  const val = (key: string) => stringValue(getValue(product.values, key))

  const feelingImage =
    getExternalMedia(product.media, "Feeling image")[0] || getExternalMedia(product.media, "Detail image")[0]

  const productImage = product.media.find(img => img.masterImage) || getExternalMedia(product.media, "Product image")[0]

  const title = props.title || val("product_marketing_name_short")

  return (
    <MediaTextBlock
      id={props.sku}
      layout={props.layout}
      contentBackground={props.textBackground}
      media={
        props.image ? (
          <DatoImage image={props.image.image} alt={props.image.alt || title} />
        ) : !props.useProductMasterImage && feelingImage ? (
          <PimImage src={feelingImage.link} style={{ objectFit: "cover" }} alt={title} />
        ) : (
          <PimImage src={productImage?.link} style={{ objectFit: "contain", background: "white" }} alt={title} />
        )
      }
      content={
        <>
          <h3>{title}</h3>
          {props.text ? (
            <p style={{ flexGrow: 1 }}>{props.text}</p>
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: val("marketing_text_short") || "" }}
              style={{ marginBottom: "1em" }}
            />
          )}
          {url && (
            <Link to={url}>
              <Button label={props.linkText} tabIndex={-1} />
            </Link>
          )}
        </>
      }
    />
  )
}

export default CtaToProductBlock
