import type {
  Association,
  AttributeTranslation,
  ExternalMedia,
  Product,
  ProductAttributeValue,
  StructureCategory,
} from "../types/plugin"
import type {
  ProductMetric,
  ProductPrice,
  RockonAssociation,
  RockonAttributeTranslation,
  RockonExternalMediaType,
  RockonProduct,
  RockonProductAttribute,
  RockonProductAttributeOption,
  RockonStructureCategory,
} from "../types/rockon"
import { transformExternalMedia } from "./media"

/**
 * Convert product attribute so that `data` is moved to a property named after its value type.
 *
 * Why: RockOn Product attributes values have properties `valueType` and `data` (among others).
 * Value of `data` can be string, number, boolean, null(?) or object.
 * To expose data in Gastby GraphQL queries all field value types should not conflict.
 * In most cases, conflicting type would mean that a warning will be reported for the user
 * and the field won’t appear in the data.
 */
function transformProductValue(value: RockonProductAttribute): ProductAttributeValue {
  const { data, attributeLabel } = value

  const val = { label: attributeLabel, data: data?.toString() }

  switch (value.valueType) {
    case "ProductMetric":
      return { ...val, type: "ProductMetric", metric: data as ProductMetric }
    case "ProductPrice":
      return { ...val, type: "ProductPrice", prices: data as ProductPrice[] }
    case "ProductOption": {
      // ProductOption seems to be always string so we put it in `text`
      // and we use translated `optionLabel` if it's set, or fall back to untranslated `data` if not
      const text = value.optionLabel || data?.toString()
      return { ...val, type: "ProductOption", text: text }
    }
    default: {
      if (typeof data === "string") {
        // TODO tutki onko JSON?
        return { ...val, type: "string", text: data }
      } else if (typeof data === "number") {
        return { ...val, type: "number", numeric: data }
      } else if (typeof data === "boolean") {
        return { ...val, type: "boolean", boolean: data }
      }
      return { ...val, type: "unknown", text: data?.toString() }
    }
  }
}

function transformProductValues(values: RockonProductAttribute[]): ProductAttributeValue[] {
  return values.filter(Boolean).map(transformProductValue)
}

type TransformFunc<T, O> = (value: T) => O

/**
 * Convert object entries/properties to array of { name: string, value: any } objects where
 * "name" comes from input property name and "value" from the value of that property.
 * Value can be optionally transformed by passing a callback function as second parameter.
 *
 * @param obj Object to convert
 * @param trasform Optional callback function. If given gets called with each property value and returned value is
 */
function arrayifyObject<T, O = T>(
  obj: {
    [key: string]: T
  },
  transform?: TransformFunc<T, O>
): {
  name: string
  value: O
}[] {
  const result: {
    name: string
    value: O
  }[] = []
  for (const [name, value] of Object.entries(obj)) {
    const val = transform ? transform(value) : (value as unknown as O)
    result.push({ name, value: val })
  }
  return result
}

/**
 * Convert product association data from API response format to Gatsby Node format
 */
function arrayifyAssociationData(data: RockonAssociation, locale: string): Association[] {
  const result: Association[] = []
  data.products.forEach(sku => {
    if (!Object.prototype.hasOwnProperty.call(data.associationData, sku)) {
      return
    }
    result.push({
      sku,
      localeId: `${sku}-${locale}`,
      data: data.associationData[sku],
    })
  })
  return result
}

/**
 * Convert product associations from API response format to Gatsby Node format
 */
function arrayifyAssociations(
  associations: Record<string, RockonAssociation>,
  locale: string
): Record<string, Association[]> {
  const result: Record<string, Association[]> = {}
  for (const [name, value] of Object.entries(associations)) {
    result[name] = arrayifyAssociationData(value, locale)
  }
  return result
}

/**
 * Convert single product object from RockOn API response format to Gastby Node format
 * @param product Product data raw from RockOn API
 * @param locale Language code (e.g. "en-US") used as parameter on Rockon API request
 */
export function processProduct(product: RockonProduct, locale: string): Product {
  const extMedia = product.values["external_media_textarea"]

  const media: ExternalMedia[] = []
  const media_images: ExternalMedia[] = []
  const media_videos: ExternalMedia[] = []
  const media_manuals: ExternalMedia[] = []
  const media_brochures: ExternalMedia[] = []
  const media_technicalImages: ExternalMedia[] = []
  const media_technicalModels: ExternalMedia[] = []
  const media_certificates: ExternalMedia[] = []
  const media_materials: ExternalMedia[] = []
  if (extMedia && extMedia.length > 0) {
    delete product.values["external_media_textarea"]
    extMedia.forEach(extM => {
      const raw = extM.data as string
      if (!raw) {
        return
      }
      const mediaList: RockonExternalMediaType[] = JSON.parse(raw)
      if (mediaList && mediaList.length) {
        media.push(...mediaList.map(transformExternalMedia))
        media_images.push(...media.filter(m => ["Product image", "Feeling image", "Detail image"].includes(m.type)))
        media_videos.push(...media.filter(m => ["Product video", "Technical video"].includes(m.type)))
        media_manuals.push(...media.filter(m => ["User manual"].includes(m.type)))
        media_brochures.push(...media.filter(m => ["Brochure"].includes(m.type)))
        media_technicalImages.push(...media.filter(m => ["Technical image"].includes(m.type)))
        media_technicalModels.push(...media.filter(m => ["3D CAD file", "2D CAD file", "BIM object"].includes(m.type)))
        media_certificates.push(...media.filter(m => ["Certificate", "Safety data sheet"].includes(m.type)))
        media_materials.push(...media.filter(m => ["Package material"].includes(m.type)))
      }
    })
  }

  const values = arrayifyObject<RockonProductAttribute[], ProductAttributeValue[]>(
    product.values,
    transformProductValues
  )

  return {
    ...product,
    locale,
    associations: arrayifyAssociations(product.associations, locale),
    values,
    media,
    media_images,
    media_videos,
    media_manuals,
    media_brochures,
    media_technicalImages,
    media_technicalModels,
    media_certificates,
    media_materials,
    product_family: product.values["product_family"]?.[0]?.data?.toString(),
    name:
      product.values["product_marketing_name_short"]?.[0]?.data?.toString() ||
      // fallback nimi tuotteelle: "product_group_2 product_family sku"
      [
        (product.values["product_group_2"]?.[0] as RockonProductAttributeOption)?.optionLabel?.toString(),
        (product.values["product_family"]?.[0] as RockonProductAttributeOption)?.optionLabel?.toString(),
        product.identifier,
      ]
        .filter(Boolean)
        .join(" "),
  }
}

export function processStructureTree(
  structure: RockonStructureCategory[],
  productData: RockonProduct[]
): StructureCategory[] {
  function handleStructure(s: RockonStructureCategory): StructureCategory {
    // Kerättävät tuotteiden arvot
    const containsProductValues: StructureCategory["containsProductValues"] = {
      product_family: [],
      color: [],
      control_panel_type: [],
      // extra_option_category: [], // nämä ei jostain syystä tule tuotteen tiedoissa?
      // controlled_devices: [],
    }

    // Etsitään tuotteiden saatavilla olevat valuet, jotka kuuluvat tähän kategoriaan
    productData
      .filter(p => p.categories.includes(s.code))
      .forEach(rockonProduct => {
        // kerätään valitut arvot tuotteilta joita tästä kategoriasta löytyy
        for (const key of Object.keys(containsProductValues)) {
          // huom toString(), toiminee tällä hetkellä vain RockonProductAttributeGeneric tyyppisillä arvoilla
          const val = rockonProduct.values[key]?.[0]?.data?.toString()

          // lisätään arrayhyn, jos vielä puuttuu
          if (val && !containsProductValues[key].includes(val)) {
            containsProductValues[key].push(val)
          }
        }
      })

    return {
      code: s.code,
      labels: s.labels ? arrayifyObject<string>(s.labels) : [],
      subCategories: s.subCategories ? s.subCategories.map(handleStructure) : [],
      containsProductValues,
    }
  }
  return structure ? structure.map(handleStructure) : []
}

export function processTranslation(translation: RockonAttributeTranslation, lang: string): AttributeTranslation {
  return { ...translation, locale: lang }
}
